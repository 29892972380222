html, body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

a:active, a:focus, input {
  outline: 0;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
  position: relative;
}

#root > .content {
  overflow: hidden;
}

@media only screen and (width <= 1280px) {
  #root {
    background-color: #fff;
  }
}

/*# sourceMappingURL=index.b1ea4688.css.map */
