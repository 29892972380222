html,
body {
  padding: 0;
  margin: 0;

  font-family: Lato, sans-serif;
  background-color: rgb(250, 250, 250);
}

a:active,
a:focus,
input {
  outline: none 0;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  position: relative;
  min-height: 100vh;
}

#root > .content {
  overflow: hidden;
}

@media only screen and (max-width: 1280px) {
  #root {
    background-color: white;
  }
}
